<template>
  <div class="list-area">
    <div v-if="showArea === 'list'" class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.appCategoryId"
            placeholder="总榜"
            @change="getSubTypeList()"
          >
            <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="searchData.appCategoryId">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.secondCategoryZh"
            :placeholder="`全部${
              searchData.appCategoryId === 1 ? '游戏榜' : '应用榜'
            }`"
          >
            <el-option
              v-for="(item, index) in appSubTypeList"
              :key="index"
              :value="item.cateName"
              :label="item.cateName"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.countryName"
            placeholder="国家"
          >
            <el-option
              v-for="(item, index) in countryList"
              :key="index"
              :value="item"
              :label="item"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            size="small"
            v-model="searchData.status"
            placeholder="应用状态"
          >
            <el-option
              v-for="item in resCheckStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-input
            size="small"
            placeholder="请输入搜索内容"
            v-model="serachKeyword"
            class="input-with-select"
          >
            <el-select
              size="small"
              slot="prepend"
              v-model="serachKeywordCode"
              placeholder="请选择类型"
            >
              <el-option label="应用名称" value="appName"></el-option>
              <el-option label="应用包名" value="packageName"></el-option>
            </el-select>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              size="small"
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4"
          ><el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          ></el-col
        >
      </el-row>
      <PageTable
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          width="50"
          slot="prefix"
          type="selection"
          :selectable="selectableRow"
          align="center"
        >
        </el-table-column>
        <template slot-scope="{ row, index }" slot="displayOrder">{{
          index + 1
        }}</template>
        <template slot-scope="{ row }" slot="status">
          <el-tag
            size="small"
            class="cursor"
            :type="row.status | tagType(resCheckStatusList)"
            v-if="!row.remarks || row.remarks == ''"
          >
            {{ row.status | typeDesc(resCheckStatusList) }}
          </el-tag>
          <el-popover trigger="click" v-else>
            <p
              style="
                max-width: 150px;
                word-wrap: break-word;
                word-break: break-all;
              "
            >
              审核意见：{{ row.remarks }}
            </p>
            <el-tag
              size="small"
              class="cursor"
              slot="reference"
              :type="row.status | tagType(resCheckStatusList)"
            >
              {{ row.status | typeDesc(resCheckStatusList) }}
            </el-tag>
          </el-popover>
        </template>

        <el-table-column
          label="操作"
          width="130px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOperate('view', row)">
              <span class="colorGreen">查看</span>
            </el-button>

            <el-button
              class="ml10"
              type="text"
              :disabled="!reviewList.includes(row.status)"
              @click="handleOperate('review', row)"
            >
              <span>审核</span>
            </el-button>

            <el-popconfirm
              v-if="row.status == 2 || row.status == 3"
              :title="`确定${row.status == 2 ? '下架' : '上架'}吗？`"
              class="ml10"
              @confirm="changeStatus(row)"
            >
              <el-button slot="reference" type="text" class="colorRed">
                <span>{{ row.status == 2 ? "下架" : "上架" }}</span>
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </PageTable>
      <div class="page-foot">
        <el-button
          size="small"
          type="primary"
          :disabled="selectionIdList.length == 0"
          @click="handleBatchOperation"
          >批量审核</el-button
        >
      </div>
    </div>
    <Detail
      v-if="showArea === 'detail'"
      :listItem="detailData"
      @cancle="handleCancel('list')"
    />
    <div v-if="dialogAuditVisible">
      <el-dialog
        center
        destroy-on-close
        :visible.sync="dialogAuditVisible"
        width="500px"
      >
        <auditModel
          :detailData="detailData"
          @submit="handleSubmitAudit"
        ></auditModel>
      </el-dialog>
    </div>
    <div v-if="dialogVisible">
      <el-dialog
        top="10vh"
        destroy-on-close
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        @closed="handleResetCreate()"
      >
      </el-dialog>
    </div>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import Detail from "./Detail.vue";
import auditModel from "./auditModel.vue";
import {
  sourceList,
  commentStatus,
  appTypeList,
  resCheckStatusList,
  countryList,
  MAXSIZE,
} from "@/common/constant/constant.js";
import {
  getReviewList,
  appInfoAuditBatch,
  getCategoryList,
  changeStatus,
} from "./indexServe";
import moment from "moment";
export default {
  name: "resCheck",
  components: { PageTable, Detail, auditModel },
  mounted() {
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      sourceList,
      appTypeList,
      resCheckStatusList,
      commentStatus,
      countryList,
      languageList: [],
      appSubTypeList: [], // 子类型列表
      searchData: {
        date: [start, end],
      },
      serachKeyword: "",
      serachKeywordCode: "appName",
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "displayOrder",
          label: "ID",
        },
        {
          key: "appName",
          label: "应用名称",
        },
        {
          key: "packageName",
          label: "应用包名",
        },
        {
          key: "devName",
          label: "开发者名称",
        },
        {
          key: "secondCategoryZh",
          label: "应用分类",
        },
        {
          key: "versionName",
          label: "版本",
        },
        {
          key: "countryName",
          label: "国家",
        },
        {
          slot: "status",
          label: "应用状态",
        },
        {
          key: "createTime",
          label: "上传时间",
        },
        {
          key: "upperTime",
          label: "上架时间",
        },
        {
          key: "reviewUserName",
          label: "审核员",
        },
      ],
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
      batchOperation: "",
      dialogVisible: false,
      dialogAuditVisible: false,
      showArea: "list", // 记录当前显示的区域，列表-list，应用详情-detail
      reviewList: [1], // 可审核的状态列表
      rowAuditorList: [], // 当前hove的审核员对应的所有语言的审核员
      auditorLoading: false, //审核员加载loading
      businessNameList: [],
    };
  },
  methods: {
    handleResetCreate() {
      this.$refs.createRef && this.$refs.createRef.resetFields();
    },
    // 获取子类型列表
    async getSubTypeList() {
      const res = await getCategoryList({
        appCategoryId: this.searchData.appCategoryId,
      });
      if (res && res.code === 0) {
        this.appSubTypeList = res.data;
      }
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getTableList({ page, size });
    },
    async changeStatus(params) {
      const obj = {
        id: params.id,
        status: params.status === 2 ? 3 : 2,
      };
      const res = await changeStatus(obj);
      if (res && res.code === 0) {
        this.getTableList({
          page: this.tableData.page,
          size: this.tableData.size,
        }); // 成功后刷新列表
        this.$message.success("操作成功");
      }
    },
    async handleOperate(operationType, row) {
      this.operationType = operationType;
      if (["create", "history"].includes(operationType)) {
        this.dialogVisible = true;
        this.detailData = { ...row };
      } else if (["view", "edit"].includes(operationType)) {
        this.showArea = "detail";
        this.detailData = {
          ...row,
        };
      } else if (operationType === "review") {
        this.detailData = { ...row };
        this.dialogAuditVisible = true;
      }
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    selectableRow(row) {
      if (!this.reviewList.includes(row.status)) return false;
      return true;
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.id);
    },
    handleBatchOperation() {
      if (this.selectionIdList.length == 0) {
        const msg =
          this.batchOperation === ""
            ? "请选择操作类型"
            : "请勾选需要操作的数据";
        this.$message.error(msg);
        return;
      }
      this.handleOperate("review", { ids: this.selectionIdList });
    },
    handleSubmitAudit(formData) {
      const data = {
        idList: formData.ids ? formData.ids : [this.detailData.id],
        ...formData,
      };
      appInfoAuditBatch(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleCancel(showArea) {
      this.showArea = showArea;
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.startTime = date[0];
        data.endTime = date[1];
      }
      if (
        this.serachKeyword &&
        this.serachKeyword.trim() !== "" &&
        this.serachKeywordCode
      ) {
        data[this.serachKeywordCode] = this.serachKeyword;
      }
      const res = await getReviewList(data);
      if (res && res.code === 0) {
        this.tableData = res.data;
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.input-with-select {
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
}
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
