<template>
  <el-dialog
    title="历史记录"
    class="historyDrawer"
    center
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
    width="75%"
  >
    <div class="mver10 dialogLabel">应用名称：{{ editData.appName }}</div>
    <div class="mver10 dialogLabel">开发者：{{ editData.developerName }}</div>
    <PageTable
      id="statisticsTable"
      class="pageTable"
      :columns="initcolumns"
      :tableData="historyLogs"
      @page-change="handleChangePage"
    >
      <template #materialUrl="{ row }">
        <el-popover placement="right" trigger="click">
          <div class="previewBox">
            <div class="content disfr ac jc">
              <img :src="row.materialUrl" alt="" />
            </div>
          </div>
          <el-button slot="reference" type="text">预览</el-button>
        </el-popover>
      </template>
      <template #verticalImgURL="{ row }">
        <el-popover placement="right" trigger="click">
          <div class="previewBoxV">
            <div class="content disfr ac jc">
              <img :src="row.verticalImgURL" alt="" />
            </div>
          </div>
          <el-button slot="reference" type="text">预览</el-button>
        </el-popover>
      </template>
      <template #squareImgURL="{ row }">
        <el-popover placement="right" trigger="click">
          <div class="previewBoxS">
            <div class="content disfr ac jc">
              <img :src="row.squareImgURL" alt="" />
            </div>
          </div>
          <el-button slot="reference" type="text">预览</el-button>
        </el-popover>
      </template>
      <template #showType="{ row }">
        <span>{{ row.showType | filterShowTypeName }}</span>
      </template>
      <template #status="{ row }">
        <el-tag
          size="small"
          class="cursor"
          :type="row.status | tagType(originalityStatus)"
          v-if="!row.remarks || row.remarks == ''"
        >
          {{ row.status | filterExamineName }}
        </el-tag>
        <el-popover trigger="click" v-else>
          <p
            style="
              max-width: 150px;
              word-wrap: break-word;
              word-break: break-all;
            "
          >
            审核意见：{{ row.remarks }}
          </p>
          <el-tag
            size="small"
            class="cursor"
            slot="reference"
            :type="row.status | tagType(originalityStatus)"
          >
            {{ row.status | filterExamineName }}
          </el-tag>
        </el-popover>
      </template>
      <template #deeplinkUrl="{ row }">
        <el-button type="text" @click="handleCopy(row.deeplinkUrl)"
          >复制链接</el-button
        >
      </template>
    </PageTable>
  </el-dialog>
</template>

<script>
import {
  originalityShowTypes,
  originalityStatus,
} from "@/common/constant/constant.js";
import PageTable from "@/components/PageTableSort";
export default {
  components: {
    PageTable,
  },
  props: {
    historyDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    historyLogs: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "historyDialog",
    event: "input",
  },
  data() {
    return {
      initcolumns: [
        { key: "ideaName", label: "创意名称" },
        { slot: "showType", label: "展示类型" },
        { slot: "materialUrl", label: "横向图" },
        { slot: "verticalImgURL", label: "竖向图  " },
        { slot: "squareImgURL", label: "方形图  " },
        { key: "submitTime", label: "提交时间" },
        { key: "createTime", label: "审核时间" },
        { slot: "status", label: "状态" },
        { key: "resolveUserName", label: "审核员" },
      ],
      tableData: {
        list: [],
        size: 10,
        page: 1,
        total: 10,
      },
      originalityStatus,
    };
  },
  filters: {
    filterExamineName(val) {
      let sec = originalityStatus.find((a) => a.value === val);
      return sec ? sec.label : "";
    },
    filterShowTypeName(val) {
      let sec = originalityShowTypes.find((a) => a.value === val);
      return sec ? sec.label : "";
    },
  },
  watch: {
    historyDialog(val) {
      if (val) {
        this.tableData.list = this.historyLogs;
      }
    },
  },
  computed: {
    dialogTableVisible: {
      get() {
        return this.historyDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleChangePage({ page, size }) {
      this.$emit("handleChangePage", { page, size });
    },
    handleCopy(value) {
      let _input = document.createElement("input"); // 直接构建input
      _input.value = value; // 设置内容
      document.body.appendChild(_input); // 添加临时实例
      _input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(_input); // 删除临时实例
      this.$message.success("复制成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.historyDrawer {
  /deep/.el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}
.dialogLabel {
  font-size: 14px;
}
.previewBox,
.previewBoxV,
.previewBoxS {
  width: 450px;
  height: 300px;
  border-radius: 15px;
  border: 1px solid #e4e4e4;
  padding: 20px 10px;
  box-sizing: border-box;
  .content {
    width: 100%;
    height: 100%;
    // border: 1px solid #e4e4e4;
    // border-radius: 10px;
    // padding: 5px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.previewBoxV {
  width: 300px;
  height: 450px;
  .content {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.previewBoxS {
  width: 350px;
  height: 350px;
  .content {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.copycode {
  visibility: hidden;
}
</style>
