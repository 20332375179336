<template>
  <div>
    <div class="fix-top">
      <el-button
        @click="$emit('cancle')"
        icon="el-icon-arrow-left"
        type="primary"
        >返回</el-button
      >
    </div>
    <div style="padding-top: 55px">
      <el-form
        :model="detailData"
        ref="formRef"
        label-width="220px"
        class="detail-form"
        label-suffix="："
      >
        <div
          class="info-area"
          v-for="(info, index) in detailData.languageInfos"
          :key="index"
        >
          <el-form-item label="应用名称" prop="apkName">
            <span>{{ info.apkNameLang }}</span>
          </el-form-item>
          <el-form-item label="包名" prop="packageName">
            <span>{{ detailData.packageName }}</span>
          </el-form-item>
          <el-form-item label="ICON" prop="iconUrl">
            <el-image
              :src="info.iconUrl"
              :preview-src-list="[info.iconUrl]"
              style="
                width: 100px;
                height: 100px;
                object-fit: cover;
                margin-bottom: 10px;
              "
            ></el-image>
          </el-form-item>
          <el-form-item label="评分" prop="score">
            <span>{{
              detailData.score && parseFloat(detailData.score).toFixed(1)
            }}</span>
          </el-form-item>
          <el-form-item label="内容评级" prop="ageLimit">
            <span>{{ detailData.ageLimit }}</span>
          </el-form-item>
          <el-form-item label="分类" prop="secondCategory">
            <span>{{ detailData.secondCategory }}</span>
          </el-form-item>
          <el-form-item label="价格" prop="inAppProduct">
            <span>{{ detailData.inAppProduct == 0 ? "免费" : "付费" }}</span>
          </el-form-item>
          <el-form-item label="开发者" prop="devName">
            <span>{{ detailData.devName }}</span>
          </el-form-item>
          <el-form-item label="全球累计下载次数" prop="downloadNum">
            <span>{{ detailData.downloadNum }}</span>
          </el-form-item>
          <el-form-item label="最近更新时间及版本" prop="downloadNum">
            <span style="margin-right: 10px">{{ detailData.modifyTime }}</span>
            <span>{{ detailData.versionName }}</span>
          </el-form-item>

          <el-form-item label="应用截图" prop="imageList">
            <div class="image-list">
              <el-image
                v-for="(item, index) in info.imageList"
                :key="index"
                :src="item.imageUrl"
                :preview-src-list="info.imageList.map((item) => item.imageUrl)"
                :class="[
                  item.imageType === 2 ? 'horizontal-image' : 'vertical-image',
                ]"
              ></el-image>
            </div>
          </el-form-item>
          <el-form-item label="应用简介" prop="briefIntroduction">
            <span>{{ info.briefIntroduction }}</span>
          </el-form-item>

          <el-form-item label="应用描述" prop="description">
            <span>{{ info.description }}</span>
          </el-form-item>

          <el-form-item label="官方网站" prop="webUrl">
            <span>{{ detailData.webUrl }}</span>
          </el-form-item>

          <el-form-item label="隐私政策" prop="appPermissionUrl">
            <span>{{ detailData.appPermissionUrl }}</span>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { queryGeneral } from "./indexServe";
export default {
  props: {
    listItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      detailData: {},
    };
  },
  created() {
    this.getDetailData();
  },
  methods: {
    async getDetailData() {
      const res = await queryGeneral({
        id: this.listItem.id,
      });
      if (res && res.code === 0) {
        this.detailData = res.data;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fix-top {
  position: fixed;
  z-index: 99;
  top: 135px;
  right: 0;
  width: 87%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
}
.horizontal-image {
  width: 200px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}
.vertical-image {
  width: 150px;
  height: 250px;
  object-fit: cover;
  margin-right: 10px;
}
</style>
