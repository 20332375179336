<template>
  <el-dialog
    title="创意审核"
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
  >
    <el-form
      style="width: 350px; margin: 0 auto"
      label-width="100px"
      label-suffix="："
      ref="formRef"
      :model="formCustom"
      :rules="rules"
    >
      <el-form-item
        label="横向图"
        prop="materialUrl"
        v-if="editData.showType != 3"
      >
        <el-image
          style="height: 80px"
          :src="formCustom.materialUrl"
          :preview-src-list="[
            formCustom.materialUrl,
            formCustom.verticalImgUrl,
            formCustom.squareImgURL,
          ]"
        >
        </el-image>
      </el-form-item>
      <el-form-item
        label="竖向图"
        prop="verticalImgUrl"
        v-if="editData.showType != 3"
      >
        <el-image
          style="height: 80px"
          :src="formCustom.verticalImgUrl"
          :preview-src-list="[
            formCustom.materialUrl,
            formCustom.verticalImgUrl,
            formCustom.squareImgURL,
          ]"
        >
        </el-image>
      </el-form-item>
      <el-form-item
        label="方形图"
        prop="squareImgURL"
        v-if="editData.squareImgURL"
        required
      >
        <el-image
          style="height: 80px; width: 80px"
          :src="formCustom.squareImgURL"
          :preview-src-list="[
            formCustom.materialUrl,
            formCustom.verticalImgUrl,
            formCustom.squareImgURL,
          ]"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="审核状态" prop="auditStatus">
        <el-radio-group
          v-model="formCustom.auditStatus"
          @change="$refs.formRef.clearValidate()"
        >
          <el-radio :label="1">审核通过</el-radio>
          <el-radio :label="3">审核驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="Deeplink链接" class="deepLink" v-if="formCustom.deeplinkUrl!==undefined">
                <el-input style="width:250px" disabled v-model="formCustom.deeplinkUrl"></el-input>
            </el-form-item> -->
      <el-form-item
        label="审核意见"
        prop="remarks"
        :rules="[
          { required: formCustom.auditStatus == 3, message: '请输入审核意见' },
        ]"
      >
        <el-input
          style="width: 250px"
          type="textarea"
          v-model="formCustom.remarks"
        ></el-input>
      </el-form-item>
      <div class="btnBox disfr ac jc">
        <el-button type="primary" class="mhor10 flex1" @click="submit"
          >确定</el-button
        >
        <el-button class="mhor10 flex1" @click="cancel">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { ideaAuditExec } from "@/common/js/api";
export default {
  props: {
    examineDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  model: {
    prop: "examineDialog",
    event: "input",
  },
  data() {
    return {
      formCustom: {
        remarks: "",
        auditStatus: 1,
        materialUrl: "",
        verticalImgUrl: "",
        squareImgURL: "",
      },
      rules: {
        materialUrl: [{ required: true, message: "请上传横向图" }],
        verticalImgUrl: [{ required: true, message: "请上传竖向图" }],
        auditStatus: [{ required: true, message: "请选择" }],
      },
    };
  },
  watch: {
    examineDialog(val) {
      if (val) {
        Object.keys(this.formCustom).forEach((key) => {
          this.formCustom[key] = this.editData[key]
            ? this.editData[key]
            : this.formCustom[key];
        });
        if (this.editData.detail == 2) {
          this.$set("formCustom", "deeplinkUrl", this.editData.deeplinkUrl);
        }
      } else {
        this.formCustom = this.$options.data().formCustom;
      }
      this.formCustom.remarks = "";
    },
  },
  computed: {
    dialogTableVisible: {
      get() {
        return this.examineDialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async cancel() {
      this.dialogTableVisible = false;
    },
    async submit() {
      let validForm;
      this.$refs.formRef.validate((valid) => {
        validForm = valid;
      });
      if (!validForm) {
        return false;
      }
      let res = await ideaAuditExec({
        ideaId: this.editData.ideaID,
        auditStatus: this.formCustom.auditStatus,
        remarks: this.formCustom.remarks,
      });
      if (res.code === 0) {
        this.$message.success("审核成功");
        this.$emit("submit");
      }
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btnBox {
  margin-top: 50px;
}
</style>
