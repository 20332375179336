import request from "@/config/serve.js";
// 查询
export function getCategoryList(params) {
  return request("post", "/gpImportList/queryList", {
    deleted: false,
    ...params,
  });
}

export function delImportData(params = {}) {
  return request("get", "/gpImportList/delete", {
    deleted: false,
    ...params,
  });
}

export function batchImport(formData) {
  return request(
    "http",
    "/gpImportList/exportList",
    {
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    },
    false
  );
}
