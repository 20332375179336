<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="140px"
    class="detail-form"
  >
    <el-form-item label="国家" prop="countryName">
      <el-select
        size="small"
        filterable
        v-model="formData.countryName"
        placeholder="请选择国家"
      >
        <el-option
          v-for="item in countryList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="榜单" prop="appCategoryId">
      <el-select
        size="small"
        filterable
        v-model="formData.appCategoryId"
        placeholder="请选择榜单"
      >
        <el-option
          v-for="item in appTypeList"
          :key="item.key"
          :label="`${item.value}榜`"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="上传Excel文件" required>
      <el-upload
        class="upload-demo"
        ref="upload"
        :on-remove="handleRemove"
        :on-change="handleUpload"
        :auto-upload="false"
        action="#"
        accept=".xls,.xlsx"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <div slot="tip" class="el-upload__tip">只能上传excel文件</div>
        <a
          href="https://obs-openplatform-dev.obs.cn-north1.ctyun.cn/uud/other/220bdaa0fcc2479f8ed10fa6881b0fae.xlsx"
          target="_blank"
          rel="noopener noreferrer"
          class="ml10"
          >模板下载</a
        >
      </el-upload>
    </el-form-item>

    <el-form-item>
      <el-button size="small" type="primary" @click="handleSubmit()"
        >确认</el-button
      >
      <el-button size="small" @click="handleCancel()">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  appStatusList,
  appTypeList,
  countryList,
} from "@/common/constant/constant.js";
export default {
  props: {
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      appTypeList,
      appStatusList,
      countryList,
      formData: {
        countryName: "",
        appCategoryId: "",
        files: [],
      },
      fileList: [],
      rules: {
        countryName: [{ required: true, message: "请选择国家" }],
        appCategoryId: [{ required: true, message: "请选择榜单" }],
      },
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleUpload(file, fileList) {
      this.fileList = fileList;
    },

    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (this.fileList.length > 0) {
            this.formData.files = this.fileList.map((item) => item.raw);
          } else {
            this.$message.error("请上传Excel文件");
            return;
          }
          this.$emit("submit", this.formData);
        } else {
          this.$message.error("请检查输入的数据");
        }
      });
    },
    handleCancel() {
      this.$emit("cancle");
    },
    // 暴露给父组件调用刷新数据
    resetFields() {
      this.$refs.formRef.resetFields();
    },
  },
};
</script>
