<template>
  <div class="list-area">
    <div v-if="showArea === 'list'" class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.appCategoryId"
            placeholder="应用类型"
          >
            <el-option
              v-for="item in appTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="searchData.appCategoryId === 1">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.subType"
            placeholder="子类型"
          >
            <el-option
              v-for="item in appSubTypeList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.reviewStatus"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in commentStatus"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-input
            placeholder="请输入搜索内容"
            v-model="searchKeyword"
            class="input-with-select"
          >
            <el-select
              style="width: 100px"
              slot="prepend"
              v-model="searchKeywordCode"
              placeholder="请选择类型"
            >
              <el-option label="APPID" :value="1"></el-option>
              <el-option label="应用名称" :value="2"></el-option>
              <el-option label="开发者名称" :value="4"></el-option>
              <el-option label="实际开发者" :value="5"></el-option>
            </el-select>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4"
          ><el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          ></el-col
        >
      </el-row>
      <!-- rowKey="releaseId" -->
      <PageTable
        rowKey="id"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
        @selection-change="handleSelectionChange"
      >
        <template slot-scope="{ row }" slot="appId">
          <div>
            <div class="urge-trial" v-if="row.isNotify == 'Y'">催审</div>
            {{ row.appId }}
          </div>
        </template>
        <template slot-scope="{ row }" slot="appCategoryId">{{
          row.appCategoryId | typeDesc(appTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="settlementType">{{
          row.settlementType | typeDesc(collaborateTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="priceScale">
          <span v-if="row.settlementType != 6">{{ row.priceScale }}</span>
        </template>
        <template slot-scope="{ row }" slot="releaseType">{{
          Number(row.releaseType) | typeDesc(releaseTypeList)
        }}</template>
        <template slot-scope="{ row }" slot="authDocUrl">
          <a :href="row.authDocUrl" v-if="row.authDocUrl" target="_blank"
            >查看</a
          >
        </template>
        <template slot-scope="{ row }" slot="authDocDate"
          >{{ row.authDocStart }}~{{ row.authDocEnd }}</template
        >
        <template slot-scope="{ row }" slot="status">
          <el-popover
            trigger="click"
            v-if="row.reviewComment || row.attachFileId"
          >
            <p
              style="
                max-width: 150px;
                word-wrap: break-word;
                word-break: break-all;
              "
            >
              审核意见：{{ row.reviewComment }}
            </p>
            <p>
              文件上传：<a
                v-if="row.attachFileId"
                :href="row.attachFileId"
                target="_blank"
                >点击查看</a
              >
            </p>
            <el-tag
              size="small"
              class="cursor"
              slot="reference"
              :type="row.status | tagType(commentStatus)"
            >
              {{ row.status | typeDesc(commentStatus) }}
            </el-tag>
          </el-popover>
          <el-tag
            size="small"
            class="cursor"
            :type="row.status | tagType(commentStatus)"
            v-else
          >
            {{ row.status | typeDesc(commentStatus) }}
          </el-tag>
        </template>
        <el-table-column
          label="操作"
          width="150px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOperate('view', row)">
              <span class="colorGreen">查看</span>
            </el-button>
            <el-button
              class="ml10"
              type="text"
              :disabled="!reviewList.includes(row.status)"
              @click="handleOperate('review', row)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <Detail
      v-if="showArea === 'detail'"
      :operationType="operationType"
      :detailData="detailData"
      :languageList="languageList"
      @cancle="handleCancel('list')"
    />
    <el-dialog
      center
      destroy-on-close
      title="商务确认"
      :close-on-click-modal="false"
      :visible.sync="dialogAuditVisible"
    >
      <auditModel
        :detailData="detailData"
        @submit="handleSubmitAudit"
      ></auditModel>
    </el-dialog>
  </div>
</template>
<script>
import Detail from "@/views/business/appManagement/apkManagement/Detail.vue"; // 应用信息
import auditModel from "./auditModel.vue";
import {
  sourceList,
  appReviewStatusList,
  appTypeList,
  collaborateTypeList,
  commentStatus,
  appSubTypeList,
  releaseTypeList,
} from "@/common/constant/constant.js";
import { appInfoAudit, getReviewList, queryGeneralDiff } from "./indexServe";
import PageTable from "@/components/PageTable.vue";
import { getLanguageList } from "@/views/business/appManagement/apkManagement/indexServe";
import moment from "moment";
export default {
  name: "infoCheck",
  components: { PageTable, Detail, auditModel },
  mounted() {
    this.getLanguageList();
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      sourceList,
      appTypeList,
      collaborateTypeList,
      commentStatus,
      appSubTypeList,
      appReviewStatusList,
      releaseTypeList,
      searchData: {
        date: [start, end],
      },
      searchKeyword: "",
      searchKeywordCode: 2,
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "appId",
          label: "APPID",
          sortable: true,
          fixed: "left",
          width: 150,
        },
        {
          key: "appName",
          label: "应用名称",
          fixed: "left",
          width: 100,
        },
        {
          key: "packageName",
          label: "应用包名",
          width: 120,
        },
        {
          slot: "appCategoryId",
          label: "应用类型",
        },
        {
          slot: "releaseType",
          label: "发布状态",
        },
        {
          key: "companyName", //
          label: "开发者名称",
        },
        {
          key: "devName",
          label: "App实际开发者",
        },
        {
          slot: "authDocUrl",
          label: "授权书",
        },
        {
          slot: "authDocDate",
          label: "授权书有效期",
        },
        // {
        //   slot: "settlementType",
        //   label: "合作模式",
        // },
        // {
        //   slot: "priceScale",
        //   label: "结算价",
        // },
        // {
        //   key: "fileSize",
        //   label: "包体大小",
        // },
        // {
        //   slot: "releaseType",
        //   label: "发布状态",
        // },
        // {
        //   key: "companyName",
        //   label: "开发者名称",
        //   width: 120,
        // },
        {
          key: "businessName",
          label: "商务对接人",
        },
        {
          key: "popularity",
          label: "知名度",
        },
        {
          key: "performance",
          label: "表现",
        },
        {
          key: "submitTime",
          label: "提交时间",
        },
        {
          key: "auditTime",
          label: "审核时间",
        },
        {
          key: "auditor",
          label: "审核员",
        },
        {
          slot: "status",
          label: "审核状态",
          width: 100,
          align: "center",
        },
      ],
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
      dialogAuditVisible: false,
      showArea: "list", // 记录当前显示的区域，列表-list，应用详情-detail
      reviewList: [100, 230, 600, 700, 620], // 可审核的状态列表
      languageList: [],
    };
  },
  methods: {
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    async handleOperate(operationType, row) {
      this.operationType = operationType;
      if (["view", "edit"].includes(operationType)) {
        this.showArea = "detail";
        const detailData = await this.getDetail({ releaseId: row.releaseId });
        this.detailData = {
          appReleaseId: row.releaseId,
          sourceType: row.sourceType, //来源类型(0开发者平台 1运营平台)
          releaseType: row.releaseType || row.developerVo.releaseType,
          appId: row.appId,
          id: row.id,
          ...detailData,
        };
      } else if (operationType === "review") {
        this.dialogAuditVisible = true;
        this.detailData = { ...row };
      }
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    selectableRow(row) {
      if (!this.reviewList.includes(row.status)) return false;
      return true;
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.releaseId);
    },
    handleSubmitAudit(formData) {
      const data = {
        appReleaseId: this.detailData.releaseId,
        releaseType: this.detailData.releaseType,
        id: this.detailData.id,
        ...formData,
      };
      appInfoAudit(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleCancel(showArea) {
      this.showArea = showArea;
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.startTime = date[0];
        data.endTime = date[1];
      }
      console.log(371, this.searchKeywordCode);
      if (this.searchKeyword) {
        data.chooseType = this.searchKeywordCode;
        data.keyword = this.searchKeyword;
      }
      const res = await getReviewList(data);
      if (res && res.code === 0) {
        this.tableData = res.data;
      }
    },
    // 获取详情
    async getDetail(params) {
      const res = await queryGeneralDiff(params);
      let detailData = {};
      if (res && res.code === 0) {
        detailData = res.data;
      }
      return detailData;
    },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
  .urge-trial {
    width: 35px;
    height: 18px;
    font-size: 10px;
    background-color: #319518;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
