<template>
  <div>
    <el-row :gutter="6">
      <el-col :span="4">
        <el-input
          placeholder="代理商账号"
          v-model="searchData.account"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          clearable
          filterable
          v-model="searchData.status"
          placeholder="审核状态"
        >
          <el-option
            v-for="item in verifiedCheckStatusTypeList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch()">
          查询
        </el-button>
      </el-col>
      <!-- <el-col :span="8">
        <div class="disfr fw7 jfd ac" style="height: 30px">
          四要素验证剩余次数：{{ checkNum }}
        </div>
      </el-col> -->
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template slot-scope="{ index }" slot="itemIndex">
        {{ index + 1 }}
      </template>
      <template slot-scope="{ row }" slot="status">
        <el-tag
          size="small"
          class="cursor"
          :type="row.status | tagType(verifiedCheckStatusTypeList)"
          v-if="!row.remark || row.remark == ''"
        >
          {{ row.status | typeDesc(verifiedCheckStatusTypeList) }}
        </el-tag>
        <el-popover trigger="click" v-else>
          <p
            style="
              max-width: 150px;
              word-wrap: break-word;
              word-break: break-all;
            "
          >
            审核意见：{{ row.remark }}
          </p>
          <el-tag
            size="small"
            class="cursor"
            slot="reference"
            :type="row.status | tagType(verifiedCheckStatusTypeList)"
          >
            {{ row.status | typeDesc(verifiedCheckStatusTypeList) }}
          </el-tag>
        </el-popover>
      </template>
      <template slot-scope="{ row }" slot="operateType">
        {{ row.operateType | typeDesc(operateTypeList) }}
      </template>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <el-button type="text" @click="detailDialog(row)" v-has="'lookinfo'">
            详情
          </el-button>
          <el-button
            v-has="'review'"
            type="text"
            @click="checkDialog(row)"
            :disabled="!(row.status == 2)"
          >
            审核
          </el-button>
          <!-- <el-button
            v-has="'review'"
            type="text"
            @click="checkDialog(row)"
            v-if="row.status==2&&row.bindUser"
            >
            审核
          </el-button>
          <el-button
            v-has="'review'"
            type="text"
            @click="checkDialog(row)"
            v-else
            :disabled="true"
            >
            审核
          </el-button> -->
          <!-- <el-button
            v-has="'review'"
            type="text"
            @click="checkDialog(row)"
            :disabled="(row.status==2&&row.bindUser)||!row.bindUser"
            >
            :disabled="[1, 3, 4].indexOf(row.status) >= 0&&!row.bindUser"
            审核
          </el-button> -->
        </template>
      </el-table-column>
    </PageTable>
    <el-dialog
      :close-on-click-modal="false"
      :title="checkData.operateType == 3 ? '账号注销审核' : '实名认证审核'"
      :visible.sync="dialogVisible"
    >
      <CheckDialog
        :checkData="checkData"
        @submit="handleSubmitAudit"
      ></CheckDialog>
    </el-dialog>
    <el-dialog
      title="实名认证资料"
      :visible.sync="EditDetailVisible"
      :close-on-click-modal="false"
    >
      <EditDetail
        :detailData="detailData"
        :imgList="imgList"
        :isRealName="true"
        :isShowUrl="false"
      ></EditDetail>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVerifiedCheckList,
  updateStatusById,
  getDetailsByIdDiff,
} from "./indexServe.js";
import CheckDialog from "./checkDialog.vue";
import EditDetail from "../developerList/Detail.vue";
import { verifiedCheckStatusTypeList } from "@/common/constant/constant.js";
import PageTable from "@/components/PageTable.vue";
export default {
  name: "agentVerifiedCheck",
  components: { CheckDialog, EditDetail, PageTable },
  data() {
    return {
      verifiedCheckStatusTypeList,
      // checkNum: null,
      searchData: {
        account: "",
        status: "",
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "itemIndex",
          label: "序号",
          width: 80,
          align: "center",
        },
        {
          key: "userId",
          label: "代理商ID",
        },
        {
          key: "account",
          label: "登录账号",
        },
        {
          key: "createTime",
          label: "提交时间",
        },
        {
          key: "updateTime",
          label: "审核时间",
        },
        // {
        //   key: "businessName",
        //   label: "商务对接人",
        // },
        {
          slot: "operateType",
          label: "操作类型",
        },
        {
          slot: "status",
          label: "状态",
        },
      ],
      checkData: "",
      detailData: "",
      imgList: [],
      dialogVisible: false,
      EditDetailVisible: false,
      operateTypeList: [
        { key: 1, value: "新增" },
        { key: 2, value: "修改" },
        { key: 3, value: "注销" },
      ],
    };
  },
  methods: {
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    async getTableList(params) {
      const data = {
        backendType: 2,
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      const res = await getVerifiedCheckList(data);
      if (res && res.code == 0) {
        this.tableData = res.data;
      }
    },
    async detailDialog(row) {
      let obj = {
        id: row.id,
        userId: row.userId,
      };
      let res = await getDetailsByIdDiff(obj);
      const imgList = [];
      if (res.code == 0) {
        this.detailData = res.data;
        if (!res.data.identityAdjunctVo) {
          this.detailData.identityAdjunctVo = {};
        }
        if (res.data.identityAdjunctVo) {
          for (let key in res.data.identityAdjunctVo) {
            imgList.push(res.data.identityAdjunctVo[key]);
          }
        }
        this.imgList = imgList;
        this.EditDetailVisible = true;
      }
    },
    checkDialog(row) {
      this.checkData = { ...row };
      this.dialogVisible = true;
    },
    async handleSubmitAudit(formData) {
      const { userId, id } = this.checkData;
      const data = {
        id,
        userId,
        ...formData,
      };
      updateStatusById(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
  },
  created() {
    this.getTableList();
  },
};
</script>

<style lang="scss" scoped></style>
