<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
  >
    <el-form-item
      :label="`${
        formData.ids
          ? '批量审核结果(共' + formData.ids.length + '条)'
          : '审核结果'
      }`"
      prop="reviewResult"
      required
    >
      <el-radio-group
        v-model="formData.reviewResult"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="审核意见"
      prop="remarks"
      :rules="[
        { required: formData.reviewResult == 2, message: '请输入审核意见' },
      ]"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="formData.remarks"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {
    this.init(this.detailData);
  },
  data() {
    return {
      formData: {},
      rules: {
        reviewResult: [{ required: true, message: "请选择审核结果" }],
      },
    };
  },
  methods: {
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formData);
        }
      });
    },
    init(data) {
      this.formData = {
        ...data,
        reviewResult: 1,
        remarks: "",
      };
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 50%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
