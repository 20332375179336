<template>
  <div>
    <div>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-input placeholder="请输入开发者" v-model="searchData.userName">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.status"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in financeInfoAuditList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <!-- <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col> -->
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
      >
        <template slot-scope="{ row }" slot="status">
          <el-tag
            size="small"
            class="cursor"
            :type="row.status | tagType(financeInfoAuditList)"
            v-if="!row.remark || row.remark == ''"
          >
            {{ row.status | typeDesc(financeInfoAuditList) }}
          </el-tag>
          <el-popover trigger="click" v-else>
            <p
              style="
                max-width: 150px;
                word-wrap: break-word;
                word-break: break-all;
              "
            >
              审核意见：{{ row.remark }}
            </p>
            <el-tag
              size="small"
              class="cursor"
              slot="reference"
              :type="row.status | tagType(financeInfoAuditList)"
            >
              {{ row.status | typeDesc(financeInfoAuditList) }}
            </el-tag>
          </el-popover>
        </template>
        <el-table-column
          label="操作"
          width="130px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              v-has="'lookinfo'"
              type="text"
              @click="handleViewDetail(row)"
            >
              <span class="colorGreen">查看</span>
            </el-button>
            <el-button
              v-has="'review'"
              class="ml10"
              type="text"
              :disabled="!reviewList.includes(row.status)"
              @click="handleReview(row)"
            >
              <span>审核</span>
            </el-button>
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <el-dialog
      center
      destroy-on-close
      title="财务审核"
      :visible.sync="dialogAuditVisible"
      :close-on-click-modal="false"
    >
      <auditModel
        :detailData="detailData"
        @submit="handleSubmitAudit"
      ></auditModel>
    </el-dialog>
    <el-dialog
      center
      destroy-on-close
      title="财务信息详情"
      :visible.sync="dialogDetailVisible"
      :close-on-click-modal="false"
    >
      <Detail :detailData="financeInfoDetail"></Detail>
    </el-dialog>
  </div>
</template>
<script>
import Detail from "./Detail.vue";
import auditModel from "./auditModel.vue";
import { financeInfoAuditList } from "@/common/constant/constant.js";
import { financeAudit, getInfoList, getInfoDetailDiff } from "./indexServe";
import moment from "moment";
import PageTable from "@/components/PageTableSort";
export default {
  name: "contractManage",
  components: { auditModel, Detail, PageTable },
  mounted() {
    this.init();
  },
  data() {
    // let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      financeInfoAuditList,
      searchData: {},
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "companyName",
          label: "开发者",
        },
        // {
        //   key: "invoice",
        //   label: "发票抬头",
        // },
        // {
        //   key: "taxId",
        //   label: "纳税人识别号",
        // },
        {
          key: "resolveTime",
          label: "审核时间",
        },
        {
          slot: "status",
          label: "状态",
        },
        {
          key: "businessName",
          label: "商务对接人",
        },
        {
          key: "resolveUserName",
          label: "审核人员",
        },
      ],
      detailData: {},
      financeInfoDetail: { invoice: {}, postInfoVo: {} },
      dialogAuditVisible: false,
      dialogDetailVisible: false,
      reviewList: [1], // 可审核的状态列表
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },

    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleViewDetail(row) {
      this.dialogDetailVisible = true;
      this.getInfoDetailDiff({ id: row.id });
    },
    handleReview(row) {
      this.detailData = { ...row };
      this.dialogAuditVisible = true;
    },
    handleSubmitAudit(formData) {
      const { id } = this.detailData;
      const data = { id, ...formData };
      financeAudit(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, status, ...rest } = this.searchData;
      const _params = {
        page: 1,
        size: 10,
        status,
        ...rest,
        ...params,
      };
      if (date) {
        _params.submitStart = date[0];
        _params.submitEnd = date[1];
      }
      _params.pageIndex = _params.page;
      _params.pageSize = _params.size;
      const res = await getInfoList(_params);
      if (res && res.code === 0) {
        const tableData = {
          list: res.data.list || [],
          page: _params.page,
          size: _params.size,
          total: res.data.total,
        };
        this.tableData = tableData;
      }
    },
    async getInfoDetailDiff(params) {
      const res = await getInfoDetailDiff(params);
      if (res && res.code === 0) {
        this.financeInfoDetail = res.data;
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
