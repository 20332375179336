<template>
  <div>
    <div class="list-area">
      <el-row :gutter="6">
        <el-col :span="4">
          <!-- <el-input placeholder="请输入发票抬头" v-model="searchData.invoice" /> -->
          <!-- <el-select
            v-model="searchData.invoice"
            multiple
            filterable
            remote
            @focus="clearChange"
            reserve-keyword
            placeholder="请输入发票抬头"
            :remote-method="getCompanyNameList"
            :loading="loading"
          >
            <el-option
              v-for="item in CompanyNameList"
              :key="item.userId"
              :label="item.invoice"
              :value="item.invoice"
            >
            </el-option>
          </el-select> -->
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.auditStatus"
            placeholder="审核状态"
          >
            <el-option
              v-for="item in developerAduitList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="6">
        <el-col :span="8">
          <el-row type="flex" align="middle">
            <span style="white-space: nowrap"> 提交日期：</span>
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="searchData.date"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
      >
        <template #invoiceType="{ row }">
          {{ row.invoiceType | typeDesc(invoiceTypeList) }}
        </template>
        <template #contentType="{ row }">
          {{ row.contentType | typeDesc(contentTypeList) }}
        </template>
        <template #unbilled="{ row }">
          <el-button type="text" @click="hanldeViewUnbilled(row)">
            明细
          </el-button>
        </template>
        <template #connect="{ row }">
          <el-button type="text" @click="hanldeViewConnect(row)">
            查看
          </el-button>
        </template>
        <template slot-scope="{ row }" slot="auditStatus">
          <el-tag
            size="small"
            class="cursor"
            :type="row.auditStatus | tagType(developerAduitList)"
            v-if="!row.remark || row.remark == ''"
          >
            {{ row.auditStatus | typeDesc(developerAduitList) }}
          </el-tag>
          <el-popover trigger="click" v-else>
            <p
              style="
                max-width: 150px;
                word-wrap: break-word;
                word-break: break-all;
              "
            >
              审核意见：{{ row.remark }}
            </p>
            <el-tag
              size="small"
              class="cursor"
              slot="reference"
              :type="row.auditStatus | tagType(developerAduitList)"
            >
              {{ row.auditStatus | typeDesc(developerAduitList) }}
            </el-tag>
          </el-popover>
        </template>

        <el-table-column
          label="操作"
          width="130px"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              class="ml10"
              type="text"
              v-has="'review'"
              :disabled="row.auditStatus == 2 || row.auditStatus == 3"
              @click="handleReview(row)"
            >
              <span>审核</span>
            </el-button>
            <a
              :href="row.filePath"
              v-has="'review'"
              class="ml10"
              v-if="row.auditStatus === 2 && row.filePath !== undefined"
              target="_blank"
              >发票预览</a
            >
          </template>
        </el-table-column>
      </PageTable>
    </div>
    <el-dialog
      center
      destroy-on-close
      title="开票审核"
      :visible.sync="dialogAuditVisible"
      :close-on-click-modal="false"
    >
      <auditModel
        @submit="handleSubmitAudit"
        :detailData="detailData"
      ></auditModel>
    </el-dialog>
    <el-dialog
      center
      destroy-on-close
      title="可开票金额"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <div>可开票金额：{{ billDetail.billableAmount }}美元</div>
      <br />
      <el-table :data="billDetail.list" border stripe show-summary>
        <el-table-column label="账期" prop="months"></el-table-column>
        <el-table-column
          label="充值金额（$）"
          prop="changeAmount"
        ></el-table-column>
        <el-table-column
          label="已开金额（$）"
          prop="invoiceAmount"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      :visible.sync="pdfPreviewDialog"
      destroy-on-close
      title="发票预览"
      width="50%"
      center
    >
      <div v-if="pdfPreviewDialog">
        <pdf
          :src="src"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          @loaded="loadPdf"
          style="width: 100%"
          ref="pdf"
        ></pdf>
        <div class="pageButton" style="margin-left: 70%">
          <el-button size="mini" @click="changePage(0)" round>上一页</el-button>
          <span> {{ currentPage }} / {{ pageCount }} </span>
          <el-button size="mini" @click="changePage(1)" round>下一页</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import auditModel from "./auditModel.vue";
import pdf from "vue-pdf-signature";
import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory.js";
import PageTable from "@/components/PageTableSort";
import {
  developerAduitList,
  invoiceTypeList,
  contentTypeList,
  MAXSIZE,
} from "@/common/constant/constant.js";
import { getInvoiceList } from "@/views/business/developer/developerList/indexServe";
import {
  auditBilling,
  getDeveloperBillList,
  getReceiveInfo,
  getBillDetails,
} from "./indexServe";
import moment from "moment";
export default {
  name: "taskCheck",
  components: { auditModel, pdf, PageTable },
  mounted() {
    this.init();
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD");
    return {
      invoiceTypeList,
      contentTypeList,
      developerAduitList,
      // CompanyNameList: [],
      loading: false,
      searchData: {
        date: [start, end],
      },
      tableData: {
        list: [],
      },
      currentPage: 0, // 页码
      pageCount: 0, // 总页数
      columns: [
        // {
        //   key: "invoice",
        //   label: "发票抬头",
        //   width: "200px",
        //   fixed: "left",
        // },
        {
          key: "applyAmount",
          label: "申请金额",
          fixed: "left",
          width: 100,
        },
        {
          slot: "unbilled",
          label: "未开票金额",
          width: 200,
        },
        {
          slot: "contentType",
          label: "发票内容",
        },
        {
          slot: "invoiceType",
          label: "发票类型",
          width: 120,
        },
        {
          slot: "connect",
          label: "收件人信息",
          width: 120,
        },
        {
          key: "createTime",
          label: "申请时间",
          width: 140,
        },
        {
          key: "modifyTime",
          label: "审核时间",
          width: 140,
        },
        {
          slot: "auditStatus",
          label: "状态",
          width: 140,
        },
        {
          key: "resolveUserName",
          label: "审核员",
        },
      ],
      src: "",
      detailData: {},
      dialogAuditVisible: false,
      dialogVisible: false,
      pdfPreviewDialog: false,
      billDetail: {
        list: [],
      },
      reviewList: [1], // 可审核的状态列表
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    previewFile(row) {
      if (row.filePath) {
        // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
        this.src = pdf.createLoadingTask({
          url: row.filePath,
          CMapReaderFactory,
        });
        this.pdfPreviewDialog = true;
      }
    },
    // 翻页
    changePage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    // pdf加载时
    loadPdf() {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
    async handleReview(row) {
      this.dialogAuditVisible = true;
      const res = await getReceiveInfo({ userId: row.userId });
      const { postInfoVo = {}, ...rest } = res.data || {};
      console.log("row", row);
      const billData = {
        name: postInfoVo.name,
        phoneNumber: postInfoVo.phoneNumber,
        addressDesc: postInfoVo.detailAddress,
        invoice: rest.invoice,
        unInvoiceAmount: rest.unInvoiceAmount,
        contentTypeDesc: this.$options.filters.typeDesc(
          row.contentType,
          contentTypeList
        ),
        invoiceTypeDesc: this.$options.filters.typeDesc(
          row.invoiceType,
          invoiceTypeList
        ),
      };
      this.detailData = { ...row, ...billData };
    },
    async hanldeViewConnect(row) {
      const res = await getReceiveInfo({ userId: row.userId });
      if (!res.data) {
        this.$message.error("暂无联系人信息");
        return;
      }
      const { postInfoVo = {} } = res.data || {};
      this.$alert(
        `
      <br/>
      <p>收件人： ${postInfoVo.name}</p>
      <p>联系电话： ${postInfoVo.phoneNumber}</p>
      <p>收件地址：${postInfoVo.detailAddress}</p>
      <br/>
      `,
        "收件人信息",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确定",
        }
      );
    },
    hanldeViewUnbilled(row) {
      this.dialogVisible = true;
      this.getBillDetails(row.userId);
    },
    handleSubmitAudit(formData) {
      auditBilling(formData).then((res) => {
        if (res && res.code === 0) {
          this.dialogAuditVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, ...rest } = this.searchData;
      const data = {
        page: 1,
        size: 10,
        ...rest,
        ...params,
      };
      if (date) {
        data.startTime = date[0];
        data.endTime = date[1];
      }
      const res = await getDeveloperBillList(data);
      if (res && res.code === 0) {
        this.tableData = res.data;
        this.$forceUpdate();
      }
    },
    // 开票-明细
    async getBillDetails(userId) {
      const res = await getBillDetails({ userId });
      if (res && res.code === 0) {
        this.billDetail = res.data;
      }
    },
    // async getCompanyNameList(query) {
    //   if (query !== "") {
    //     this.loading = true;
    //     setTimeout(() => {
    //       getInvoiceList({ invoiceKey: query }).then((res) => {
    //         this.loading = false;
    //         if (res && res.code === 0) {
    //           this.CompanyNameList = res.data || [];
    //         }
    //       });
    //     }, 1000);
    //   } else {
    //     this.CompanyNameList = [];
    //   }
    // },
    // clearChange() {
    //   this.CompanyNameList = [];
    // },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;

  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;

    div {
      margin-right: 5px;
    }
  }

  .imgsArea {
    display: flex;
    flex-wrap: wrap;

    .image {
      width: 80px;
      flex-shrink: 0;
    }
  }
}
</style>
