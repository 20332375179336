var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"formRef",staticClass:"auditModel",attrs:{"model":_vm.formData,"rules":_vm.rules,"size":"small"}},[_c('el-form-item',{attrs:{"label":`${
      _vm.formData.ids
        ? '批量审核结果(共' + _vm.formData.ids.length + '条)'
        : '审核结果'
    }`,"prop":"reviewResult","required":""}},[_c('el-radio-group',{on:{"change":function($event){return _vm.$refs.formRef.clearValidate()}},model:{value:(_vm.formData.reviewResult),callback:function ($$v) {_vm.$set(_vm.formData, "reviewResult", $$v)},expression:"formData.reviewResult"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("通过")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("不通过")])],1)],1),_c('el-form-item',{attrs:{"label":"审核意见","prop":"remarks","rules":[
      { required: _vm.formData.reviewResult == 2, message: '请输入审核意见' },
    ]}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6, maxRows: 6 },"show-word-limit":"","maxlength":"100","suffix-icon":"el-icon-date","autocomplete":"off"},model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", $$v)},expression:"formData.remarks"}})],1),_c('div',{staticClass:"disfr jc ac"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleSubmitAudit}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }