import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=c440bc1e&scoped=true&"
import script from "./Detail.vue?vue&type=script&lang=js&"
export * from "./Detail.vue?vue&type=script&lang=js&"
import style0 from "./Detail.vue?vue&type=style&index=0&id=c440bc1e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c440bc1e",
  null
  
)

export default component.exports