import request from "@/config/serve.js";
// 查询
export function getReviewList(params) {
  return request("POST", "/gpImportApp/queryList", {
    ...params,
  });
}

// 查询分类
export function getCategoryList(params) {
  return request("post", "/gpImportApp/categoryGpList", params);
}

//批量审核
export function appInfoAuditBatch(data) {
  return request("post", "/gpImportApp/batch/appAudit", data);
}

// 查询详情
export function queryGeneral(params) {
  return request("post", "/gpImportApp/queryDetail", params);
}
// 查询详情
export function changeStatus(params) {
  return request("post", "/gpImportApp/appAudit", params);
}
